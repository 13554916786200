<template>
  <div class="joinTeamDialog">
    <h1>Team Joinen</h1>
    <TextInput :input-title="fields.ingameName.label" v-model:input-text="fields.ingameName.text"
               :inputPlaceholder="fields.ingameName.placeholder" :textFieldType="fields.ingameName.type"
               :validationFunction="fields.ingameName.validFunction" :doValidation="fields.ingameName.useValidation"
               :ref="(el)=>setJoinTeamRefs(el,fields.ingameName.id)"></TextInput>
    <TextInput v-if="isPrivate" :input-title="fields.teamPassword.label" v-model:input-text="fields.teamPassword.text"
               :inputPlaceholder="fields.teamPassword.placeholder" :textFieldType="fields.teamPassword.type"
               :validationFunction="fields.teamPassword.validFunction" :doValidation="fields.teamPassword.useValidation"
               :ref="(el)=>setJoinTeamRefs(el,fields.teamPassword.id)"></TextInput>

    <div class="actions">
      <PlainButton button-text="Abbrechen" :button-function="closeFunction" button-type="fluid">
        Abbrechen
      </PlainButton>
      <PlainButton :button-function="()=>joinTeam()" button-type="normal">
        Bestätigen
      </PlainButton>
    </div>
    <transition name="dialogBackground">
      <div class="dialogBackground"
        v-if="showAgeDialog"
        @click="() => { 
            showAgeDialog=false;
          }"></div>

    </transition>
    
    <AgeDialog isJoin v-if="showAgeDialog" :minimum_age="minimum_age" :close-function="closeFunction" button-text="Bestätigen" :requestBody="team_body" :after-join="afterJoin">
      Bestätige bitte dein Alter um dem Team beizutreten.      
    </AgeDialog>
  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput";
import {teamService} from "@/services";
import {mapActions} from "vuex";
import PlainButton from "@/components/Buttons/PlainButton";
import AgeDialog from "@/components/Dialogs/AgeDialog";

export default {
  name: "JoinTeamDialog",
  components: {PlainButton, TextInput,AgeDialog},

  props: {
    isPrivate: Boolean,
    closeFunction: Function,
    minimum_age:Number,
    afterJoin: Function,
    team_id: Number,
    ingame_name_source: String,
  },
  data() {
    return {
      refs:{},
      showAgeDialog:false,
      fields: {
        ingameName: {
          label: 'Dein '+this.ingame_name_source+' Name',
          text: '',
          id: 'ingame',
          placeholder: '',
          type: 'text',
          validFunction:(text)=>{
            return text.length === 0 ? {
              isInvalid: true,
              errorText: this.ingame_name_source + ' Name eingeben'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          useValidation: true
        },
        teamPassword: {
          label: 'Passwort',
          text: '',
          id: 'password',
          placeholder: '',
          type: 'password',
          validFunction(text) {
            return text.length === 0 ? {
              isInvalid: true,
              errorText: 'Passwort eingeben'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          useValidation: true
        }

      }
    }
  },
  computed: {
    ingame_name() {
      return Object.values(this.fields).find(value => value.id === 'ingame').text
    },
    password() {
      return Object.values(this.fields).find(value => value.id === 'password').text
    },
    team_body(){
      return {
              "player_name": this.ingame_name,
              "password": this.isPrivate ? this.password : null,
              "team_id": this.team_id
            }
    }
  },
  methods: {
    ...mapActions('alert',["error","clear"]),
    setJoinTeamRefs(el, name){
      if(el){
        this.refs[name]=el;
      }
    },
    joinTeam() {
      if (this.validateTexts(this)) {
        let player_name = this.ingame_name
        let password = this.password

        if(this.minimum_age){
          this.showAgeDialog=true;
        }else{
          teamService.joinTeam(
            {
              "player_name": player_name,
              "password": this.isPrivate ? password : null,
              "team_id": this.team_id
            }).then(
            teamMate => {
              this.closeFunction();
              this.afterJoin()
              this.clear();
              return teamMate;
            },
            error => {
              this.error(error);
            }
        )
        }
        
      }
    },
    validateTexts(context) {
      let isValid = true;
      Object.values(context.fields).forEach(field => {
        if (!(!context.isPrivate && field.id === "password")) {

          context.refs[field.id].validate(field.text)
          isValid = !context.refs[field.id].isInvalid && isValid;
        }

      });
      return isValid;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";

.joinTeamDialog {
  box-shadow: 0 5px 20px rgba(0, 0%, 0%, 0.15);
  position: fixed;
  display: flex;
  flex-direction: column;
  
  padding: 32px;
  align-items: flex-start;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;



  .dialogBackground-enter-active,
.dialogBackground-leave-active {
  transition: opacity 0.25s;
}

.dialogBackground-enter-from,
.dialogBackground-leave-to {
  opacity: 0;
}

  .dialog-enter-active,
  .dialog-leave-active {
    transition: 0.25s ease;
  }

  .dialog-enter-to,
  .dialog-leave-from {
    transform-origin: center;
    transform: translateX(-50%) translateY(-50%) scale(1);
  }

  .dialog-enter-from,
  .dialog-leave-to {
    transform-origin: center;
    transform: translateX(-50%) translateY(-50%) scale(0);
  }


  .dialogBackground {
  z-index: 101;
  position: fixed;
  border-radius: 16px;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);

  width: 100%;
  height: 100%;
}


  @include theme() {
    background-color: theme-get('background-color-2');
  }
  border-radius: 16px;

  h1 {
    @include theme() {
      color: theme-get('header-1')
    }
    margin-top: 0;
    line-height: 1.05;
    font-size: 3em;
    font-weight: 600;
  }

  .actions {
    margin-top: 48px;
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .joinTeamDialog {
    align-items: center;

    h1 {
      font-size: 2em;
    }
    .actions {
      margin-top: 16px;
      flex-direction: column-reverse;
    }
  }



}
</style>
