<template>
  <div id="emptyList">

    <div>
      <h3>
        <slot name="title"></slot>
      </h3>
      <p>
        <slot name="description"></slot>
      </p>
    </div>
    <div class="emptyIllustration">
      <div>

      </div>
      <div>

      </div>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyList"
}
</script>

<style scoped lang="scss">
@import "src/styles/themes";
  .emptyIllustration{

    display: flex;
    gap: 32px;
    div{
      padding:16px;
      border-radius: 16px;
      @include theme{
        background-color:theme-get("primary-color")
      }
    }
  }
  #emptyList{
    text-align: center;
    display: flex;

    justify-content: center;
    flex-direction: column;
    gap:32px;
    margin-top:64px;
    align-items: center;
    width: auto;
    height: auto;
  }
  h3{
    @include theme{
      color: theme-get("header-3")
    }
    line-height: 1;
    margin:0;
    font-size: 2.5em;
  }
  p{
    @include theme{
      color: theme-get("text-2")
    }
    font-size: 1.25em;
  }
  @media screen and (max-width: 768px){
    h3{
      font-size: 2em;
    }
    p{
      font-size: 1em;
    }
  }
@media screen and (max-width: 480px){
  h3{
    font-size: 1.75em;
  }
  p{
    font-size: 1em;
  }
  .emptyIllustration{
    gap:16px;
    div{
      padding:8px;
    }
  }
}

</style>