<template>
  <div class="ageDialog">
    <div class="infoText">
      <div class="material-icons warningIcon" >
        warning
      </div>
      <div class="dialog-content">
        <div>
          <h1>Altersbestätigung</h1>
          <p>
            <slot>

            </slot>
          </p>
        </div>
        
        <div id="ageCheck">
          <Checkbox :is-active="isOld" :click-function="() => { toggleOld() }">

          </Checkbox>
          <p>Ich bestätige hiermit, dass ich älter als {{minimum_age}} Jahre bin. </p>
        </div>
      </div>
    </div>
    <div class="actions">
      <PlainButton :button-function="closeFunction" v-if="showCloseButton" button-type="fluid">
        {{ cancelButtonText }}
      </PlainButton>
      <PlainButton  :button-function="confirmFunction" >
        {{buttonText}}
      </PlainButton>
    </div>
  </div>
</template>

<script>
import PlainButton from "@/components/Buttons/PlainButton";
import {teamService} from "@/services";
import Checkbox from "@/components/Inputs/Checkbox"
export default {
  name: "AgeDialog",
  components: {PlainButton, Checkbox},
  data(){

    return {
      isOld:false,
    
    }
  },
  props:{
    buttonText:String,
    isJoin: Boolean,
    requestBody: Object,
    cancelButtonText:{
      default:"Abbrechen"
    },
    minimum_age:Number,
    afterAdd: Function,
    afterJoin:Function,
    closeFunction:Function,
    showCloseButton:Boolean,
  },
  methods:{
    toggleOld(){
      this.isOld=!this.isOld
    },
    confirmFunction(){
      if(this.isOld){
        if(this.isJoin){
          teamService.joinTeam(
            this.requestBody).then(
            teamMate => {
              this.closeFunction();
              this.afterJoin()
              this.clear();
              return teamMate;
            },
            error => {
              this.error(error);
            }
        )
        }else{
          teamService.createTeam(this.requestBody).then(
                  team => {
                    this.closeFunction();
                    this.afterAdd();
                    return team;
                  },
                  error => {
                    this.error(error)
                  }
          )
        }

      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";
  .ageDialog{
    box-shadow: 0 5px 20px rgba(0,0,0,0.15);
    position: fixed;
    display: flex;
    flex-direction: column;
    padding:32px;
    align-items: center;
    z-index:101;
    left: 50%;
    top: 50%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    height: auto;
    @include theme(){
      background-color: theme-get('background-color-2');
    }
    
    background-color: white;
    border-radius: 16px;

    .dialogBackground-enter-active,
    .dialogBackground-leave-active {
      transition: opacity 0.25s;
    }

    .dialogBackground-enter-from,
    .dialogBackground-leave-to {
      opacity: 0;
    }




    .infoText{

      .dialog-content{
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      h1{
        @include theme(){
          color: theme-get('header-1');
        }
        margin:0;
      }
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin:  8px 0;
      gap: 24px;
      display: flex;
      justify-content: flex-start;
      p{
        margin:0;
        @include theme(){
          color: theme-get('text-1');
        }
      }
      #ageCheck {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:8px;
    width: 100%;

    .switch {
      cursor: pointer;
      padding: 8px;
      font-size: 32px;

      @include theme() {
        color: theme-get('text-1');
      }
    }
    p{
      text-align: left;
    }
  }
    }
    .actions{
      width: 100%;
      margin-top: 16px;
      display: flex;
      gap:16px;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column-reverse;
    }
  }
  .errorIcon{
    padding:16px;
    font-size: 2em;
    color:#D55353;
    background-color:hsla(0, 61%, 58%,0.15);
    border-radius: 100%;
  }
  .warningIcon{
    padding:16px;
    font-size: 2em;
    color:#E9A727;
    background-color:hsla(40, 82%, 53%,0.15);
    border-radius: 100%;
  }
  @media screen and (max-width: 1024px){
    .actions{
      margin-top: 32px !important;

    }
  }
  @media screen and (max-width: 768px){
    .ageDialog{
      h1{
        font-size: 2em;
      }
      .infoText{
        .material-icons{
          font-size: 3em;
        }
        color: #444444;

      }

    }
  }

</style>
