<template>
  <div>
    <transition name="showsidebardrawer">
      <div id="sidebar" v-if="showSidebarDrawer">
        <div class="material-icons sidebaractions" v-if="isSmall" @click="showSidebarDrawer=false">
          clear
        </div>
        <div>
          <h2>{{ title }}</h2>
        </div>
        <div v-for="game in elementMap" :key="game+'element'" class="element-group">
          <h3 v-if="game.tournaments">{{game.parent}}</h3>
          <router-link v-for="element in game.tournaments" class="sidebarelement" :key="element.id" :to="'/'+baseLoc+'/'+element.id" @click="()=>{if(isSmall){showSidebarDrawer=false}}" >
            <div :class="{'active':activeElement?activeElement===element.id:$route.meta.subpage===element.id}">
              <div>
                <div class="material-icons" v-if="element.icon">
                  {{element.icon}}
                </div>
                {{ element.title }}
              </div>
              <div v-if="showAddition" class="addition">
                {{element.addition}}
                <div class="material-icons" >
                  {{additionIcon}}
                </div>
              </div>
            </div>


          </router-link>


        </div>


      </div>
    </transition>
    <transition name="showSidebarDrawer">
      <div class="material-icons showsidebar" v-if="isSmall&&!showSidebarDrawer" @click="showSidebarDrawer=true">
        {{ mobileIcon }}
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: "GameSideBar",
  props: {
    baseLoc: String,
    title: String,
    elements: Array,
    activeElement: Number,
    elementAction: Function,
    mobileIcon: String,
    additionIcon: String,
    showAddition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSidebarDrawer: true,
      isSmall: false
    }
  },
  created() {
    this.onResize();
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    changeActiveElement(tournament_id) {
      if (this.isSmall) {
        this.showSidebarDrawer = false;
      }
      this.elementAction(tournament_id)
    },
    onResize() {
      if (window.innerWidth < 1400 && this.isSmall === false) {
        this.isSmall = true;
        this.showSidebarDrawer = false;
      } else if (window.innerWidth > 1400 && this.isSmall === true) {
        this.isSmall = false;
        this.showSidebarDrawer = true;
      }
    },
  },
  computed: {
    elementMap() {
      let gameMap = []
      Array.from(new Set(this.elements.map(value => value.parent.game_name))).forEach(value => {

        gameMap.push({
          parent: value,
          tournaments: this.elements.filter(element => element.parent.game_name === value)
        });

      })
      return gameMap;
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../../styles/themes";

a {
  text-decoration: none;

}

.showsidebardrawer-enter-active, .showsidebardrawer-leave-active {
  transition: 0.5s ease;
}

.showsidebardrawer-enter-from, .showsidebardrawer-leave-to {
  transform: translateX(-100%);
}


.showsidebar {
  z-index: 60;
  @include theme() {
    background-color: theme-get('primary-color');
    box-shadow: theme-get('shadow-1');
  }
  color: white;
  cursor: pointer;
  width: auto;
  position: fixed !important;
  top: 122px !important;
  left: 0 !important;
  padding: 12px 12px 12px 24px;
  border-radius: 0 32px 32px 0;
  right: auto !important;

  text-align: left;

  &:hover {
    filter: brightness(0.8) saturate(0.8);

    color: #FFFFFF;
  }
}

.sidebaractions {
  cursor: pointer;
  z-index: 60;
  transition: 0.25s;
  padding: 8px;
  font-size: 24px;
  @include theme() {
    color: theme-get('primary-color');
    background-color: theme-get('primary-background');
    box-shadow: theme-get('shadow-1');
  }
  border-radius: 32px;

  height: auto;
  position: absolute;
  right: 20px;
  top: 20px;

  &:hover {
    @include theme() {
      background-color: theme-get('primary-color');
    }

    color: #FFFFFF;
  }
}

#sidebar {
  z-index: 80;
  overflow-y: auto;
  @include theme() {
    box-shadow: theme-get('shadow-1');
    background-color: theme-get('background-color-1');
  }

  position: fixed;
  height: 100%;
  left: 0;
  width: 352px;
  padding: 64px 0 88px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 80px;


  h2 {
    font-size: 2.25em;
    @include theme() {
      color: theme-get('header-1')
    }
    margin: 0 0 32px 32px;
  }
}
.element-group{
  margin-bottom: 32px;
  padding:0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3{
    margin-top:0;
    margin-bottom: 8px;
    padding-left: 40px;
    @include theme(){
      color:theme-get('header-3')
    }
  }
}
.sidebarelement {
  border-radius: 0;
  cursor: pointer;
  text-align: left;
  width: 100%;
  font-size: 1.125em;
  transition: 0.25s;
  @include theme() {
    color: theme-get('text-1');
  }
  display: block;
  &:hover{
    @include theme(){
      background-color: theme-get('primary-hover');
    }

  }

  .addition {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    .material-icons {
      padding: 0
    }
  }

  padding: 8px 24px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    &.active {
      transition: 0.25s;
      position: relative;
      border-radius: 8px;

      @include theme() {
        background-color: theme-get('primary-background');
        color: theme-get('primary-color')
      }
      font-weight: 600;
      /*&::after {
        transition: 0.25s;
        box-sizing: border-box;
        content: "";
        position: absolute;
        right: 16px;
        padding: 6px;
        height: auto;
        border-radius: 6px;
        @include theme(){
          background-color: theme-get('primary-color');
        }
      }*/
    }
  }

}

@media screen and (max-width: 480px) {
  #sidebar {
    width: 80%;
  }
  .sidebarelement {
    width: 100%;
  }
}
</style>
