<template>
  <div class="tournaments">
    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>
    <LeftSideNav v-if="tournaments.length>0">
      <template v-slot:sideNavigation>
        <GameSideBar v-if="tournaments.length>0" title="Spiele"
                     :elements="tournaments.map(value => {return{'id':value.tournament_id,'title':value?.title??value?.game.game_name, 'addition':value?.team_size, 'parent':this.getParent(value)}})"
                     :active-element="active" :element-action="setTournament"
                     mobile-icon="sports_esports"
                     show-addition
                     addition-icon="person"
                     base-loc="tournaments"
        />
      </template>
      <template v-slot:mainContent>
        <router-view>
          <TournamentDetail :selected-tournament="selectedTournament"
                            :get-tournaments="getTournaments"></TournamentDetail>
        </router-view>
      </template>
    </LeftSideNav>
    <div id="noEvent" v-else>
      <EmptyList>
        <template v-slot:title>
          Zurzeit läuft kein Turnier...
        </template>
        <template v-slot:description>
          Komm später wieder, um teilzunehmen wenn ein Turnier stattfindet.
        </template>
      </EmptyList>
    </div>
    <transition name="error-show">
      <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
    </transition>
  </div>

</template>

<script>
import ErrorPop from "@/components/Popups/ErrorPop";
import GameSideBar from "@/components/Navigation/GameSidebar";
import {mapState} from "vuex";
import {teamService} from "@/services";
import FullscreenLoading from "@/components/Indicators/Loading/FullscreenLoading";
import TournamentDetail from "@/components/Tournaments/TournamentDetail";
import LeftSideNav from "@/layouts/LeftSideNav";
import EmptyList from "@/components/List/EmptyList";





export default {
  name: "Tournaments",

  components: {
    EmptyList,
    LeftSideNav,
    TournamentDetail,
    FullscreenLoading,
    GameSideBar,
    ErrorPop,
  },
  data() {
    return {
      active: 0,
      tournaments: []
    }
  },
  created() {
    this.active = parseInt(this.$route.params.tournament_id ?? 0)
    this.getTournaments()
    this.$watch(
        () => this.$route.params,
        (toParams) => {
          this.active = this.tournaments !== null ? parseInt(toParams.tournament_id ?? this.tournaments[0]?.tournament_id) : 0
        }
    )
  },
  computed: {
    ...mapState('alert', {
          alertMessage: 'message',
          alertType: 'type'
        }
    ),
    selectedTournament() {
      return this.tournaments.find(value => value.tournament_id === this.active)
    },
    userParticipates() {
      let teams = []
      this.selectedTournament.teams.forEach(value => {
        teams.push.apply(teams, value.team_mates.map(mate => mate.user.user_id))
      });
      return teams.includes(this.user?.userdata.user_id)
    }


  },
  methods:
      {
        setTournament(tournament_id) {
          this.active = tournament_id
          this.$router.push('/tournaments/' + this.active)
        },
        getTournaments() {
          teamService.fetchTournaments().then(gameEvent => {
            this.tournaments = gameEvent?.tournaments ?? []

            if (this.tournaments !== undefined) {
              if (this.active === 0) {
                this.active = this.tournaments[0]?.tournament_id ?? 0
              } else if (!this.tournaments.some(value => value.tournament_id === this.active)) {
                this.active = this.tournaments[0]?.tournament_id
              }
              this.$router.push('/tournaments/' + this.active)
            }
          },)
        },
        getParent(tournament){
          let parent_game={}
          parent_game=tournament.game
          while(parent_game.parent_game!=null){
            parent_game=parent_game.parent_game;
          }
          return parent_game
        }
      },

}

</script>

<style lang="scss" scoped>
@import "../styles/themes.scss";

.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

#noEvent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tournaments {
  min-height: 100%;
  width: 100%;
  @include theme {
    background-color: theme-get('background-color-2');
  }
}

@media screen and (max-width: 1400px) {
  #tournamentContainer {
    width: 100%;
    margin: 0;
  }
}

</style>
