<template>
  <div class="addTeamDialog">
    <h1>Neues Team</h1>
    <TextInput :input-title="field.label" v-model:input-text="field.text"  v-for="field in Object.values(fields).filter(value=>(value.id!=='password' && (value.forSingleplayer || !this.singleplayer)))" :inputPlaceholder="field.placeholder" :textFieldType="field.type" :validationFunction="field.validFunction" :doValidation="field.useValidation" :key="field.id" :ref="(el)=>setAddTeamRefs(el,field.id)"></TextInput>
    <div v-if="!singleplayer" id="password">
      <div class="passwordProtectiontoggle">
        <Checkbox :is-active="isPasswordProtected" :click-function="togglePasswordProtection"/>
        <p>Passwortgeschützt</p>
      </div>
      <transition name="password">
<!--      <TextInput input-title="Passwort" v-if="isPasswordProtected" v-model:input-text="password"></TextInput>-->
        <TextInput v-if="isPasswordProtected" :input-title="fields.teamPassword.label" v-model:input-text="fields.teamPassword.text"   :inputPlaceholder="fields.teamPassword.placeholder" :textFieldType="fields.teamPassword.type" :validationFunction="fields.teamPassword.validFunction" :doValidation="fields.teamPassword.useValidation" :ref="(el)=>setAddTeamRefs(el,fields.teamPassword.id)"></TextInput>
      </transition>
    </div>

    <div class="actions">
      <PlainButton button-text="Abbrechen" :button-function="closeFunction" button-type="fluid">
        Abbrechen
      </PlainButton>
      <PlainButton :button-function="()=>createTeam()">
        Bestätigen
      </PlainButton>
    </div>
    <transition name="dialogBackground">
      <div class="dialogBackground"
        v-if="showAgeDialog"
        @click="() => { 
            showAgeDialog=false;
          }"></div>
    </transition>
    
     <transition name="dialog">
      <AgeDialog v-if="showAgeDialog" :minimum_age="minimum_age" :close-function="closeFunction" button-text="Bestätigen" :requestBody="team_body" :after-add="afterAdd">
        Bestätige bitte dein Alter um dein Team zu erstellen.      
      </AgeDialog>
     </transition>      
    
  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput";
import Checkbox from "@/components/Inputs/Checkbox";
import {teamService} from "@/services";
import {mapActions} from "vuex";
import PlainButton from "@/components/Buttons/PlainButton";
import AgeDialog from "@/components/Dialogs/AgeDialog";
export default {
  name: "AddTeamDialog",
  components: {PlainButton, Checkbox, TextInput, AgeDialog},
  props: {
    tournament_id: Number,
    closeFunction: Function,
    minimum_age: Number,
    afterAdd: Function,
    ingame_name_source: String,
    singleplayer: Boolean,
  },
  data() {
    return {
      refs:{},
      showAgeDialog: false,
      isPasswordProtected: false,
      fields: {
        teamname: {
          forSingleplayer: false,
          label: 'Team Name',
          text: '',
          id: 'teamname',
          placeholder: 'DieFischis',
          type: 'text',
          validFunction: function (text) {
            if(text.length===0){
              return  {
                isInvalid: true,
                errorText: 'Teamname eingeben'
              }
            }else if(text.length>32){
              return  {
                isInvalid: true,
                errorText: 'Teamname darf nicht länger als 32 Zeichen lang sein'
              }

            }else {
              return{
                isInvalid: false,
                errorText: ''
              }
            }
          },
          useValidation: true
        },
        ingameName: {
          forSingleplayer: true,
          label: 'Dein '+this.ingame_name_source+' Name',
          text: '',
          id: 'ingame',
          placeholder: '',
          type: 'text',
          validFunction:(text)=>{
            return text.length === 0 ? {
              isInvalid: true,
              errorText: this.ingame_name_source + ' Name eingeben'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          useValidation: true
        },
        teamPassword:{
          label: 'Passwort',
          text: '',
          id: 'password',
          placeholder: '',
          type: 'password',
          validFunction(text) {
            return !/^.{2,64}$/.test(text) ? {
              isInvalid: true,
              errorText: 'Das Passwort muss zwischen 2 und 64 Zeichen lang sein.'
            } : {
              isInvalid: false,
              errorText: ''
            };
          },
          useValidation: true
        }

      },
    }
  },
  computed:{
    team_name(){return Object.values(this.fields).find(value => value.id==='teamname').text},
    ingame_name(){return Object.values(this.fields).find(value => value.id==='ingame').text},
    password(){return Object.values(this.fields).find(value => value.id==='password').text},
    team_body(){return {
            'tournament_id': this.tournament_id,
            'team_name': this.team_name.length>0?this.team_name:null,
            'password': this.isPasswordProtected ? this.password : null,
            'creator_player_name': this.ingame_name
          }
    }


  },
  methods: {
    ...mapActions('alert',["error"]),
  togglePasswordProtection() {
    this.isPasswordProtected = !this.isPasswordProtected
  },
    setAddTeamRefs(el, name){
      if(el){
        this.refs[name]=el;
      }
    },


    createTeam() {
        let dialogcontext=this;
        if(this.validateTexts(dialogcontext)){
          if(this.minimum_age){
            this.showAgeDialog=true
          }else{
            teamService.createTeam(this.team_body).then(
                  team => {
                    this.closeFunction();
                    this.afterAdd();
                    return team;
                  },
                  error => {
                    this.error(error)
                  }

            );
          }



          
        }
      
      
    },

    validateTexts(context) {
      let isValid = true;

      Object.values(context.fields).forEach(field => {
        if (!(!context.isPasswordProtected && field.id === "password") && (field.forSingleplayer || !context.singleplayer)) {
          context.refs[field.id].validate(field.text)
          isValid = !context.refs[field.id].isInvalid && isValid;
        }

      });
      return isValid;
    }
  },

}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";

#password{
  width: 100%;
}

.passwordProtectiontoggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  p{
    margin:0;
  }
  font-weight: 500;
  @include theme() {
    color: theme-get('header-3')
  }

  font-size: 1.25em;

}

.password-enter-active, .password-leave-active {
  transition: 0.25s;
}

.password-enter-from, .password-leave-to {
  transform: scale(0);
  opacity: 0;
}

.addTeamDialog {
  
  box-shadow: 0 5px 20px rgba(0, 0%, 0%, 0.15);
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: flex-start;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;


  .dialogBackground-enter-active,
.dialogBackground-leave-active {
  transition: opacity 0.25s;
}

.dialogBackground-enter-from,
.dialogBackground-leave-to {
  opacity: 0;
}



  .dialog-enter-active,
  .dialog-leave-active {
    transition: 0.25s ease;
  }

  .dialog-enter-to,
  .dialog-leave-from {
    transform-origin: center;
    transform: translateX(-50%) translateY(-50%) scale(1);
  }

  .dialog-enter-from,
  .dialog-leave-to {
    transform-origin: center;
    transform: translateX(-50%) translateY(-50%) scale(0);
  }


  .dialogBackground {
  z-index: 101;
  position: fixed;
  border-radius: 16px;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);

  width: 100%;
  height: 100%;
}



  @include theme() {
    background-color: theme-get('background-color-2');
  }

  border-radius: 16px;

  h1 {
    @include theme() {
      color: theme-get('header-1');
    }

    margin-top: 0;
    line-height: 1.05;
    font-size: 3em;
    font-weight: 600;
  }

  .actions {
    margin-top: 48px;
    display: flex;
    gap: 16px;
    justify-content: space-evenly;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .addTeamDialog {
    align-items: center;
    h1 {
      font-size: 2em;
    }
  }
.addTeamDialog{
  .actions {
    margin-top: 16px;
    width: 100%;
    flex-direction: column-reverse;
  }
}


}
</style>
