<template>
  <div class="detailElement">
    <div class="material-icons icon">
      {{icon}}
    </div>
    <div>
      <p>{{title}}</p>
      <h3>{{description}}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailElement",
  props:{
    title:String,
    description:String,
    icon:String
  }
}
</script>

<style scoped lang="scss">

@import "../../styles/themes.scss";
  .detailElement{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:16px;

    padding-top:8px;
    padding-bottom: 8px;
    div{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p{
        line-height: 1;
        font-size: 1.125em;
        margin:0;
        @include theme {
          color: theme-get("text-2");
        }
      }
      h3{
        margin:0;
        font-weight: 600;
        font-size: 1.5em;
        @include theme {
          color: theme-get("listheader");
        }
      }
    }
    .icon{
      font-size: 32px;
      @include theme {
        color: theme-get("primary-color");
      }
    }
  }
</style>