<template>
  <div id="tournamentdetail">
    <div id="title">
      <div class="title-text">
        <h1>{{ selectedTournament?.title ?? selectedTournament.game.game_name }}</h1>
        <div v-html="compiledDescription" class="description">
        </div>
      </div>
      <div class="tournamentAction">
        <PlainButton :button-function="addTeam" button-type="success" scale-by-child is-small is-slim
                     v-if="selectedTournament.current_registration_phase < 5">
          <div class="addTeam">
            <div class="material-icons">
              add
            </div>
            <div>
              {{selectedTournament.team_size>1?'Team erstellen':'Spiel beitreten'}}
            </div>
          </div>
        </PlainButton>

        <div v-if="selectedTournament.max_teams" class="team-limit">
          <span class="material-icons">
            info
          </span>
          <p>Maximale Teamanzahl: {{this.selectedTournament.max_teams}}</p>
        </div>
      </div>

    </div>
    <div id="details">
      <DetailElement icon="groups" title="Teilnehmer:"
                     :description="selectedTournament?.teams?.length.toString().concat(' ', selectedTournament?.team_size > 1 ? (selectedTournament?.teams?.length === 1 ? 'Team' : 'Teams') : 'Spieler')"
                     v-if="selectedTournament?.teams?.length > 0"></DetailElement>
      <DetailElement icon="emoji_events" title="Preis:" v-if="selectedTournament?.prize?.length > 0"
                     :description="selectedTournament?.prize"/>
      <DetailElement icon="event" title="Start:" :description="formatDate(new Date(selectedTournament?.datetime))">
      </DetailElement>
    </div>
    <div class="listContainer" v-if="selectedTournament?.teams?.length > 0 && !selectedTournament.winner">
      <TeamItem v-for="team in sortedList" :team="team" :team_size="selectedTournament.team_size" :key="team.team_id"
                :join-function="joinTeam" :is-private="team.has_password" :leave-function="leaveTeamWarning"
                :is-in-team="user != null ? team.team_mates.some(value => value.user.user_id === user?.userdata?.user_id) : false"
                :go-to-team-view="goToTeamView"/>
    </div>
    <div v-else-if="selectedTournament.winner" class="listContainer ">
      <div class="winnerContainer">
        <div id="winner-title">
          <span class="material-icons">
            emoji_events
          </span>
          <div>
            <h2>Gewinner</h2>
            <p>Der Gewinner des Turniers ist:</p>
          </div>
        </div>
        <TeamItem :team="selectedTournament.winner" :team_size="selectedTournament.team_size"
                  :key="selectedTournament.winner.team_id"
                  :is-in-team="user != null ? selectedTournament.winner.team_mates.some(value => value.user.user_id === user?.userdata?.user_id) : false"
                  :go-to-team-view="goToTeamView" :show-btn="false"></TeamItem>


      </div>
      <TeamItem v-for="team in sortedList" :team="team" :team_size="selectedTournament.team_size" :key="team.team_id"
                :join-function="joinTeam" :is-private="team.has_password" :leave-function="leaveTeamWarning"
                :is-in-team="user != null ? team.team_mates.some(value => value.user.user_id === user?.userdata?.user_id) : false"
                :go-to-team-view="goToTeamView"/>

    </div>
    <div v-else id="noTournament">
      <EmptyList>
        <template v-slot:title>
          Niemand da...
        </template>
        <template v-slot:description>
          Es hat sich noch niemand für dieses Turnier angemeldet.
          Sei der erste!
        </template>
      </EmptyList>
    </div>


    <transition name="dialogBackground">
      <div class="dialogBackground"
           v-if="addTeamDialogVisible ||
        joinTeamDialogVisible ||
        loginAlertDialogVisible ||
        leaveWarningDialogVisible ||
        notVerifiedAlertDialogVisible ||
        notOnDiscordDialogVisible ||
        teamRegistrationClosedDialogVisible ||
        playerParticipationClosedDialogVisible ||
        alreadyInTeamDialogVisible ||
        teamFullDialogVisible||
        teamLimitReachedDialog"
           @click="() => {
          addTeamDialogVisible = false;
          joinTeamDialogVisible = false;
          loginAlertDialogVisible = false;
          leaveWarningDialogVisible = false;
          notVerifiedAlertDialogVisible=false;
          notOnDiscordDialogVisible = false;
          teamRegistrationClosedDialogVisible = false
          playerParticipationClosedDialogVisible = false
          alreadyInTeamDialogVisible = false
          teamFullDialogVisible = false
          teamLimitReachedDialog = false
          }">
      </div>
    </transition>
    <transition name="dialog">
      <AddTeamDialog v-if="addTeamDialogVisible" :minimum_age="selectedTournament.game.minimum_age"
                     :after-add="getTournaments"
                     :singleplayer="this.selectedTournament.team_size === 1"
                     :close-function="() => { addTeamDialogVisible = false }"
                     :tournament_id="selectedTournament.tournament_id"
                     :ingame_name_source="this.ingameNameSource"></AddTeamDialog>
    </transition>
    <transition name="dialog">
      <JoinTeamDialog v-if="joinTeamDialogVisible" :minimum_age="selectedTournament.game.minimum_age"
                      :after-join="getTournaments"
                      :close-function="() => { joinTeamDialogVisible = false }" :is-private="joinDialogPrivate"
                      :team_id="joinTeamID"
                      :ingame_name_source="this.ingameNameSource"></JoinTeamDialog>
    </transition>

    <!--      Error Dialogs-->
    <transition name="dialog">
      <ErrorDialog button-text="Zum Login" :is-warning="false" :show-close-button="true" dialog-title="Geht nicht!"
                   :close-function="() => { loginAlertDialogVisible = false }"
                   :confirm-function="() => this.$router.push('/login')"
                   v-if="loginAlertDialogVisible">Du musst
        eingeloggt sein, um einem Team beizutreten oder eins zu erstellen.
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="OK" :is-warning="false" :show-close-button="false" dialog-title="Nicht verifiziert"
        :confirm-function="() => { notVerifiedAlertDialogVisible = false }" v-if="notVerifiedAlertDialogVisible">
        Dein Account ist noch nicht per Mail Verifiziert
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="OK" :is-warning="false" :show-close-button="false"
                   dialog-title="Team-Anmeldung geschlossen"
                   :confirm-function="() => { teamRegistrationClosedDialogVisible = false }"
                   v-if="teamRegistrationClosedDialogVisible">Die Anmeldung für dieses Turnier ist zurzeit geschlossen.
        Es
        können keine Teams mehr erstellt werden.
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="OK" :is-warning="false" :show-close-button="false"
                   dialog-title="Spieler-Anmeldung geschlossen"
                   :confirm-function="() => { playerParticipationClosedDialogVisible = false }"
                   v-if="playerParticipationClosedDialogVisible">Die Anmeldung für dieses Turnier ist zurzeit
        geschlossen. Es
        können keine Teamänderungen gemacht werden.
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="OK" :is-warning="false" :show-close-button="false" dialog-title="Team voll"
                   :confirm-function="() => { teamFullDialogVisible = false }" v-if="teamFullDialogVisible">Das Team ist
        voll. Such dir andere Spielpartner.
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="OK" :is-warning="false" :show-close-button="false" dialog-title="Schon in einem Team."
                   :confirm-function="() => { alreadyInTeamDialogVisible = false }" v-if="alreadyInTeamDialogVisible">Du
        bist schon in einem Team. Verlasse es um diese Aktion durchzuführen
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="Verlassen" :is-warning="true" :show-close-button="true" dialog-title="Bist du sicher?"
                   :close-function="() => { leaveWarningDialogVisible = false }" :confirm-function="this.leaveTeam"
                   v-if="leaveWarningDialogVisible">Bist du sicher, dass du dein Team verlassen willst?
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="Verstanden" :is-warning="false" :show-close-button="false"
        dialog-title="Kein Discord Account verknüpft!" :confirm-function="() => { notOnDiscordDialogVisible = false }"
        v-if="notOnDiscordDialogVisible">Um Teilzunehmen muss dein Discord Account verknüpft und verifiziert sein.
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="Verstanden" :is-warning="true" :show-close-button="false"
                   dialog-title="Das Turnier ist voll." :confirm-function="() => { teamLimitReachedDialog = false }"
                   v-if="teamLimitReachedDialog">Das eingestellte {{selectedTournament.team_size>1?'Team':'Spieler' }}-Limit wurde erreicht.
      </ErrorDialog>
    </transition>


  </div>
</template>

<script>
import {teamService} from "@/services";
import PlainButton from "@/components/Buttons/PlainButton";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";
import JoinTeamDialog from "@/components/Dialogs/JoinTeamDialog";
import AddTeamDialog from "@/components/Dialogs/AddTeamDialog";
import EmptyList from "@/components/List/EmptyList";
import {mapState} from "vuex";
import DetailElement from "@/components/Tournaments/DetailElement";
import TeamItem from "@/components/Tournaments/TeamItem";
import {marked} from 'marked';
import DOMPurify from 'dompurify'

export default {
  name: "TournamentDetail",
  components: {
    TeamItem,
    DetailElement,
    PlainButton,
    ErrorDialog,
    JoinTeamDialog,
    AddTeamDialog,
    EmptyList
  },
  computed: {
    ...mapState('account', ['user']),
    userParticipates() {
      let team_mates = []
      this.selectedTournament.teams.forEach(value => {
        team_mates.push.apply(team_mates, value.team_mates.map(mate => mate.user_id))
      });
      return team_mates.includes(this.user?.userdata.user_id)
    },
    sortedList() {
      let unsorted = this.selectedTournament?.teams.filter(value => value.team_id !== this.selectedTournament.winner?.team_id);
      return unsorted.sort((a, b) => b.team_mates.some(value => value.user_id === this.user?.userdata?.user_id) - a.team_mates.some(value => value.user_id === this.user?.userdata?.user_id));
    },
    compiledDescription() {
      let rawMD = this.selectedTournament?.description;
      if (rawMD !== undefined && rawMD !== null) {
        rawMD = DOMPurify.sanitize(marked(rawMD));
      }
      return rawMD ?? '';
    },
  },
  props: {
    selectedTournament: Object,
    getTournaments: Function
  },
  data() {
    return {
      currentDialog: '',
      addTeamDialogVisible: false,

      loginAlertDialogVisible: false,
      notVerifiedAlertDialogVisible: false,
      teamRegistrationClosedDialogVisible: false,
      teamFullDialogVisible: false,
      alreadyInTeamDialogVisible: false,
      playerParticipationClosedDialogVisible: false,
      notOnDiscordDialogVisible: false,
      teamLimitReachedDialog: false,
      joinTeamDialogVisible: false,
      joinDialogPrivate: false,
      joinTeamID: 0,
      leaveTeamID: 0,
      leaveWarningDialogVisible: false,
      ingameNameSource: '',

    }
  },
  methods: {
    allowedToPerformAction(team, isCreate,isJoin, isLeave) {
      return this.user !== null && ((isCreate && (!this.selectedTournament.max_teams||this.selectedTournament.max_teams>this.selectedTournament.teams.length)&&(!this.userParticipates)) || (isJoin && team.team_mates.length < team.tournament.team_size&&(!this.userParticipates)) ||isLeave&&this.userParticipates) && this.selectedTournament.current_registration_phase <= (isCreate ? 1 : 2) && this.user?.userdata.verified && (!this.selectedTournament.discord_required || this.user?.userdata.discord_is_verified)
    },
    showEventDialog(team, isCreate,isJoin, isLeave) {
      this.loginAlertDialogVisible = false
      this.notVerifiedAlertDialogVisible = false
      this.teamRegistrationClosedDialogVisible = false
      this.teamFullDialogVisible = false
      this.alreadyInTeamDialogVisible = false
      this.playerParticipationClosedDialogVisible = false
      this.teamLimitReachedDialog = false

      if (this.user === null) {
        this.loginAlertDialogVisible = true
      }else if (!this.user?.userdata.verified) {
        this.notVerifiedAlertDialogVisible = true
      } else if (!this.user?.userdata.discord_is_verified&&this.selectedTournament.discord_required) {
        this.notOnDiscordDialogVisible = true
      }  else if (isCreate && this.selectedTournament.current_registration_phase > 1) {
        this.teamRegistrationClosedDialogVisible = true
      } else if (this.selectedTournament.current_registration_phase > 2) {
        this.playerParticipationClosedDialogVisible = true
      } else {
        if ((((isCreate && (!this.selectedTournament.max_teams||this.selectedTournament.max_teams>this.selectedTournament.teams.length))||(isJoin&& team.team_mates.length < team.tournament.team_size))&&(!this.userParticipates)) && !isLeave) {
          if (this.userParticipates) {
            this.alreadyInTeamDialogVisible = true
          }
        }else if(this.selectedTournament.max_teams<=this.selectedTournament.teams.length){
          this.teamLimitReachedDialog =true
        } else if (!isLeave) {
          if (this.userParticipates) {
            this.alreadyInTeamDialogVisible = true
          } else if (team.team_mates.length === team.tournament.team_size) {
            this.teamFullDialogVisible = true
          }
        }
      }
    },
    formatDate(date) {
      return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getUTCFullYear() + " - " + ((date.getHours()) + ":" + ('0' + date.getMinutes()).slice(-2))
    },
    addTeam() {
      if (this.allowedToPerformAction(null, true, false, false)) {
        this.addTeamDialogVisible = true
        this.ingameNameSource = this.selectedTournament.ingame_name_source
      } else {
        this.showEventDialog(null, true,false, false)
      }
    },
    joinTeam(team) {
      if (this.allowedToPerformAction(team, false, true, false)) {
        this.joinTeamID = team.team_id
        this.joinDialogPrivate = team.has_password
        this.joinTeamDialogVisible = true
        this.ingameNameSource = this.selectedTournament.ingame_name_source
      } else {
        this.showEventDialog(team, false, true,false)
      }
    },
    leaveTeamWarning(team) {
      if (this.allowedToPerformAction(team, false, false, true)) {
        this.leaveTeamID = team.team_id;
        this.leaveWarningDialogVisible = true
      } else {
        this.showEventDialog(team, false,false, true)
      }
    },
    leaveTeam() {
      teamService.leaveTeam(this.leaveTeamID).then(() => {
            this.leaveWarningDialogVisible = false
            this.getTournaments()
            this.leaveTeamID = 0;

          }
      )
    },
    goToTeamView() {
      this.$router.push('/team/' + this.selectedTournament.tournament_id);
    },
  },

}
</script>

<style lang="scss" scoped>
@import "../../styles/themes.scss";

.error-show-enter-active,
.error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.dialogBackground-enter-active,
.dialogBackground-leave-active {
  transition: opacity 0.25s;
}

.dialogBackground-enter-from,
.dialogBackground-leave-to {
  opacity: 0;
}

.dialog-enter-active,
.dialog-leave-active {
  transition: 0.25s ease;
}

.dialog-enter-to,
.dialog-leave-from {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.dialog-enter-from,
.dialog-leave-to {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(0);
}

#tournamentdetail {
  padding: 64px 128px;
  display: grid;
  grid-template-columns: 1fr 324px;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title details"
    "list list";
  grid-gap: 48px;
}

.dialogContainer {
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
}

.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}

.tournamentAction{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:16px;
  .team-limit{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:8px;
    .material-icons{
      @include theme {
        color: theme-get("text-2")
      }
    }
    p{
      font-size: 1.125em !important;
    }
  }
}

.tournamentDetails {
  text-align: center;
  display: flex;

  @include theme {
    color: theme-get("text-2")
  }

  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: auto;
  flex-wrap: wrap;


  .material-icons {
    font-size: 0.625em;
  }
}

.addTeam {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 8px;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.winnerContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;

  @include theme {
    background-color: theme-get('primary-background');
  }

  border-radius: 16px;
}

.listContainer {
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-area: list;
  height: 100%;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  #winner-title {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    .material-icons {
      font-size: 48px;

      @include theme {
        color: theme-get('primary-color');
      }
    }

    h2 {
      font-size: 3em;
      margin: 0;

      @include theme {
        color: theme-get('header-2');
      }
    }

    p {
      margin: 0;

      @include theme {
        color: theme-get('text-2');
      }
    }
  }

}

#title {
  grid-area: title;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;

  .title-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  h1 {
    font-weight: 600;
    text-align: left;
    line-height: 1.05;

    @include theme {
      color: theme-get('header-1');
    }

    font-size: 4em;
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    @include theme {
      color: theme-get('text-2');
    }

    margin: 0;
    font-size: 1.5em;
    line-height: 1;
    text-align: left;

  }

  .description :deep(p) {
    @include theme {
      color: theme-get('text-1');
    }

    overflow-wrap: break-word;
    font-size: 1.25em;
    text-align: left;
    margin: 0;
  }

  .description :deep(li) {
    @include theme {
      color: theme-get('text-1');
    }

    overflow-wrap: break-word;
    font-size: 1.25em;
    text-align: left;
  }

  .description :deep(h1) {
    @include theme {
      color: theme-get('text-1');
    }

    overflow-wrap: break-word;
    font-size: 2.5em;
    text-align: left;
    margin: 0;
  }

  .description :deep(h2) {
    @include theme {
      color: theme-get('text-1');
    }

    overflow-wrap: break-word;
    font-size: 1.75em;
    text-align: left;
    margin: 0;
  }

  .description :deep(h3) {
    @include theme {
      color: theme-get('text-1');
    }

    overflow-wrap: break-word;
    font-size: 1.5em;
    text-align: left;
    margin: 0;
  }

  margin-bottom: 32px;
}

#noTournament {
  /*display: flex;
  width: 100%;
  align-items: center;*/
  grid-area: list
}

#details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  grid-area: details;
}

@media screen and (max-width: 1400px) {
  #tournamentdetail {
    padding: 64px 64px;
  }
}

@media screen and (max-width: 1024px) {
  #tournamentdetail {
    align-items: center !important;
    display: flex;
    flex-direction: column;
    padding: 64px 32px;
  }
  .tournamentAction{
    align-items: center;
  }
}

@media screen and (max-width: 768px) {

  #title {
    align-items: center;

    .description :deep(p) {
      @include theme {
        color: theme-get('text-1');
      }

      font-size: 1em;

    }

    h1 {
      text-align: center;
      font-size: 4em;
      margin-bottom: 16px;
    }

    p {
      text-align: center;
      font-size: 1em;
    }
  }

  .tournamentDetails {
    justify-content: center;

  }

}

@media screen and (max-width: 480px) {
  #title {
    h1 {
      font-size: 3em;
      margin-bottom: 8px;
    }
  }

  #tournamentdetail {
    padding: 64px 16px;
  }

  .listContainer {
    #winner-title {
      h2 {
        text-align: start;
        font-size: 2em;
      }

      p {
        text-align: start;
        font-size: 1em;
      }
    }
  }


}</style>
